<script>
import {lookup2,getLookupItemText} from '@/apis/common'
export default {
  props:{
    serviceOrder:{
      type: Object,
      required: true
    }
  },
  components: {},
  data() {
    return {
      orderDetail:{},
      company:{},
      contact:{},
      lookupRawData:{},
      lookupData:{
        certLev:[],
        certType:[],
        auditType:[],
        wtSource:[],
        wwChannel:[],                
        cnty:[]
      },
    };
  },
  computed:{
    country(){
      return getLookupItemText(this.lookupRawData["CNTY"],this.company.country);
    },    
    energySource(){
      return getLookupItemText(this.lookupRawData["ENG_SOURCE"],this.orderDetail.energySource);
    },    
    multipleSite(){
      return getLookupItemText(this.lookupRawData["YES_NO"],this.orderDetail.multipleSite);
    },    
    involvesNewEnergy(){
      return getLookupItemText(this.lookupRawData["NEW_ENERGY"],this.orderDetail.involvesNewEnergy);
    },    
    onsitePowerGeneration(){
      return getLookupItemText(this.lookupRawData["YES_NO"],this.orderDetail.onsitePowerGeneration);
    },    
    energySupplytoOtherSites(){
      return getLookupItemText(this.lookupRawData["YES_NO"],this.orderDetail.energySupplytoOtherSites);
    },    
    carbonOffset(){
      return getLookupItemText(this.lookupRawData["YES_NO"],this.orderDetail.carbonOffset);
    },    
    onsiteCanteen(){
      return getLookupItemText(this.lookupRawData["CANTEEN_DORMITORY"],this.orderDetail.onsiteCanteen);
    },    
    millsLease(){
      return getLookupItemText(this.lookupRawData["YES_NO"],this.orderDetail.millsLease);
    },         
    fireExtinguisher(){
      return getLookupItemText(this.lookupRawData["YES_NO"],this.orderDetail.fireExtinguisher);
    },  
    fireSuppressionSystem(){
      return getLookupItemText(this.lookupRawData["YES_NO"],this.orderDetail.fireSuppressionSystem);
    }   
  },
  watch: {
  },
  created(){
  },
  mounted() {
    this.init()
  },
  methods: {
    async init(){
      // 初始化数据字典
      this.lookupRawData = await lookup2("INDUSTRY,CNTY,YES_NO,ENG_SOURCE,NEW_ENERGY,CANTEEN_DORMITORY");
      this.initDetail()
    },
    initDetail(){
      this.contact = {
        contact:this.serviceOrder.order.contact,
        title:this.serviceOrder.order.title,
        mobile:this.serviceOrder.order.mobile,
        email:this.serviceOrder.order.email,
      }
      let orderDetail = JSON.parse(this.serviceOrder.order.orderDetail)
      let companyInfo = JSON.parse(this.serviceOrder.order.companyInfo)
      this.company = {...this.serviceOrder.order,...companyInfo}
      this.orderDetail = {...orderDetail}
    }
  }
};
</script>

<template>
  <div class="mb-0">
    <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    01
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">{{$t("generalService.serviceInfo")}}</h5>
                        </div>
                    </div>
                    <div class="card-body">
                      <div class="row mb-3">
                        <div class="col-md-4">
                          <div class="infoField">{{$t("ghg.year")}}</div>
                          <div class="infoData">{{orderDetail.year}}</div>
                        </div>                              
                      </div>                
                      <div class="row mb-3">
                        <div class="col-lg-4">
                        <div class="infoField">{{$t("ghg.energySource")}}</div>
                        <div class="infoData">{{energySource}}</div>                                         
                      </div>
                        <div class="col-lg-4">
                          <div class="infoField">{{$t("ghg.multipleSite")}}</div>
                          <div class="infoData">{{multipleSite}}</div>                             
                        </div>   
                        <div class="col-lg-4">
                          <div class="infoField">{{$t("ghg.involvesNewEnergy")}}</div>
                          <div class="infoData">{{involvesNewEnergy}}</div>                                                           
                        </div>
                      </div>
                      
                      <div class="row mb-3">
                        <div class="col-md-4">
                          <div class="infoField">{{$t("ghg.onsitePowerGeneration")}}</div>
                          <div class="infoData">{{onsitePowerGeneration}}</div>                                                                                    
                        </div>
                        <div class="col-md-4">
                          <div class="infoField">{{$t("ghg.energySupplytoOtherSites")}}</div>
                          <div class="infoData">{{energySupplytoOtherSites}}</div>                                                                               
                        </div>
                        <div class="col-md-4">
                          <div class="infoField">{{$t("ghg.carbonOffset")}}</div>
                          <div class="infoData">{{carbonOffset}}</div>                                                    
                        </div>        
                      </div>            
                      <div class="row">
                        <div class="col-md-12 mb-3">
                          <div class="infoField">{{$t("ghg.mainProduction")}}</div>
                          <div class="infoData">{{orderDetail.mainProduction}}</div>
                        </div>                     
                      </div>      
                      <div class="row">
                        <div class="col-md-12 mb-3">
                          <div class="infoField">{{$t("ghg.operationFacility")}}</div>
                          <div class="infoData">{{orderDetail.operationFacility}}</div>
                        </div>                        
                      </div>                             
                      <div class="row">
                        <div class="col-md-12 mb-3">
                          <div class="infoField">{{$t("ghg.onsiteCanteen")}}</div>
                          <div class="infoData">{{onsiteCanteen}}</div>                                                        
                        </div>                                                 
                      </div>                                                   
                      <div class="row">
                        <div class="col-md-12 mb-3">
                          <div class="infoField">{{$t("ghg.subcontractedProcess")}}</div>
                          <div class="infoData">{{orderDetail.subcontractedProcess}}</div>
                        </div>
                      </div>

                      <div class="row mb-3">
                        <div class="col-md-4">
                          <div class="infoField">{{$t("ghg.millsLease")}}</div>
                          <div class="infoData">{{millsLease}}</div>                                                     
                        </div>
                        <div class="col-md-4">
                          <div class="infoField">{{$t("ghg.fireExtinguisher")}}</div>
                          <div class="infoData">{{fireExtinguisher}}</div>                                                     
                        </div>
                        <div class="col-md-4">
                          <div class="infoField">{{$t("ghg.fireSuppressionSystem")}}</div>
                          <div class="infoData">{{fireSuppressionSystem}}</div>                                                    
                        </div>        
                      </div> 

                      <div class="row mb-3">
                          <div class="infoField">{{$t("generalService.serviceDate")}}</div>
                          <div class="infoData">{{orderDetail.auditDateFrom}}-{{orderDetail.auditDateTo}}</div>                     
                      </div>
                      <div class="row">                      
                          <div class="col-md-6 mb-3">
                            <div class="infoField">{{$t("fem.promoteBrand")}}</div>
                            <div class="infoData">{{company.promoteBrand}}</div>       
                          </div>
                        </div>
                    </div>
                </div>
                <div class="card border shadow-none mb-5">
                    <div class="card-header d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar">
                                <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    02
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="card-title">{{$t("common.baseInfo")}}</h5>
                        </div>
                    </div>
                    <div class="card-body">
              <div class="row">
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.clientNameLocal")}}</div>
                        <div class="infoData">{{company.clientName}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.clientNameEn")}}</div>
                        <div class="infoData">{{company.clientNameEn}}</div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-lg-12 mb-2">
                        <div class="infoField">{{$t("common.taxCode")}}</div>
                        <div class="infoData">{{company.taxCode}}</div>
                      </div>
                  </div>      
                  <div class="row">
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.country")}}</div>
                        <div class="infoData">{{country}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.province")}}</div>
                        <div class="infoData">{{company.province}}</div>
                      </div>
                  </div>                              
                  <div class="row">
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.addressLocal")}}</div>
                        <div class="infoData">{{company.address}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.addressEn")}}</div>
                        <div class="infoData">{{company.addressEn}}</div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-lg-12 mb-2">
                        <div class="infoField">{{$t("common.station")}}</div>
                        <div class="infoData">{{company.station}}</div>
                      </div>
                  </div>                     
                  <div class="row">
                    <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.contact")}}</div>
                        <div class="infoData">{{contact.contact}}</div>
                      </div>
                      <div class="col-lg-6 mb-2">
                        <div class="infoField">{{$t("common.title")}}</div>
                        <div class="infoData">{{contact.title}}</div>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                        <div class="infoField">{{$t("common.mobile")}}</div>
                        <div class="infoData">{{contact.mobile}}</div>
                      </div>
                      <div class="col-lg-6">
                        <div class="infoField">{{$t("common.email")}}</div>
                        <div class="infoData">{{contact.email}}</div>
                      </div>
                  </div>
            </div>
                </div>
  </div>
</template>
<style lang="scss" scoped>
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
</style>