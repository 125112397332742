<script>
import Vue from "vue";
import Swal from "sweetalert2";
import Steps from "../../components/v-steps";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import Email from "../../components/email";
import {disabledBeforeTodayDates,getUserText,downFile,formatDate,dateDiff,formatEmailInfo,getLookupItemText,checkRole,checkUser} from '@/apis/common'
import {queryStaffList} from '@/apis/user'
import {getNetDiskFile} from '@/apis/disk'
import {serviceOrderSchedule,generateConfirmLetter,setServiceOrderStatus,reportReviewApplication,reportReviewConfirm,setServiceOrderCS} from '@/apis/serviceorder'
import store from '@/state/store'
import {getTemplate,sendEmail} from '@/apis/email'
import {
  required
} from "vuelidate/lib/validators";

export default {
  props:{
    serviceOrder:{
      type: Object,
      required: true
    }
  },
  validations: {
    schedule: {
      serviceDate: { 
            required,
      },
      serviceDateEnd: { 
            required,
      },
      schedulingDate:{
        req(value){
            console.info(value)
            if (this.serviceOrder.schedulingMode=="3"){
              return value!=null && value.trim().length > 0
            }else{
              return true;
            } 
        },
      },
      schedulingDateEnd:{
        req(value){
            if (this.serviceOrder.schedulingMode=="3"){
              return value!=null && value.trim().length > 0
            }else{
              return true;
            } 
        },
      },
    }
  },
  components: {Steps,DatePicker,Multiselect,Email},
  data() {
    return {
      stepMap:{
        1:"PS",
        2:"SC",
        3:"P",
        4:"IP",
        5:"PP",
        6:"PR",
        7:"RF",
        8:"F"
      },
      curCS:[],
      csList:[],
      submitted:false,
      showSendMail:false,
      showLog:false,
      staffList:[],
      travelList:[{value:1,label:"Pre-Travel"},{value:2,label:"After-Travel"}],
      templateId:"CONFIRM-LETTER-HIGG",
      confirmLetterEmail:{},
      lookupData:{
        clientSource:[],
        companyType:[],
        industry:[]
      },
      disabledDates: disabledBeforeTodayDates,
      showScheduleForm:false,
      verificationFiles:[],
      schedule:{
        serviceDate:"",
        serviceDateEnd:"",
        schedulingDate:"",
        schedulingDateEnd:"",
        workers:[],
        dataWorkers:[],
        reviewer:[],
        travels:[],
        dataTravels:[],
        observers:[],
        observerTravels:[],
      }
    };
  },
  computed:{
    showAssignButton(){
      return checkRole("CS_LEADER,SYS_ADMIN") 
    },
    stepsLabel(){
      return [this.$t("serviceOrder.stepPS"), this.$t("serviceOrder.stepSC"), this.$t("serviceOrder.stepP"), this.$t("serviceOrder.stepIP"), this.$t("serviceOrder.stepPP"), this.$t("serviceOrder.stepPR"), this.$t("serviceOrder.stepRF"), this.$t("serviceOrder.stepF")]
    },
    schedulingModeText:function(){
      if(this.serviceOrder.schedulingMode=="1"){
        return this.$t("serviceOrder.announced")
      }else if(this.serviceOrder.schedulingMode=="2"){
        return this.$t("serviceOrder.unannounced")
      }else if(this.serviceOrder.schedulingMode=="3"){
        return this.$t("serviceOrder.semiAnnounced")
      }else{
        return ""
      }
    },
    userInfo:function(){
      if(store.state.auth.currentUser){
        return store.state.auth.currentUser
      }else{
        return {nickName:'',userName:''}
      }
    },
    reviewerList(){
      let workers = this.serviceOrder.workers;
      if(workers!=null && workers!=undefined){
        return this.staffList.filter((item)=>{return !workers.includes(item.userName)})
      }else{
        return []
      }
      
    },
    order(){
      if(this.serviceOrder.order==null){
        return {}
      }else{
        return this.serviceOrder.order
      }
    },
    currentStep(){
      for(let index in this.stepMap){
        if(this.stepMap[index]==this.serviceOrder.serviceStatus){
          return parseInt(index)
        }
      }
      return 1
    },
    showScheduleButton(){
      let role = checkRole("CS,SYS_ADMIN")
      if(this.serviceOrder.serviceStatus=="PS" || this.serviceOrder.serviceStatus=="SC" ||(this.serviceOrder.confirmationLetterReceipt==null && this.serviceOrder.confirmationLetterReceipt=="")){
        return true && role
      }else{
        return false && role
      }
    }
  },
  watch: {
  },
  created(){
    this.init()
  },
  mounted() {
  },
  methods: {
    handleAssignCS(){
      this.$refs.csModal.show()
    },
    assignCS(){
      let cs = this.curCS.userName
      setServiceOrderCS({serviceId:this.serviceId,cs:cs}).then(()=>{
        this.$refs.csModal.hide()
        Vue.swal(this.$t("common.assignCompleted"));
      })
    },
    getAuditUserText(user){
      return getUserText(this.staffList,user)
    },
    getTravelText(value){
      return getLookupItemText(this.travelList,value)
    },
    
    async init(){
      await queryStaffList({roles:["AUDIT"]}).then((res)=>{
          this.staffList = res.data;
      })
      await queryStaffList({roles:["CS"]}).then((res)=>{
          this.csList = res.data;
      })
      getNetDiskFile({code:["HIGG_VF_FILE","SLCP_VF_FILE","CCUPI","CEL"]}).then((res)=>{
          this.verificationFiles = res.data;
      })
    },
    changeStatusEnable(index){    
      //服务进行中以前步骤不允许自行设置
      if(index<4)return false
      let curIndex = 1;
      for(let key in this.stepMap){
        if(this.stepMap[key]==this.serviceOrder.serviceStatus){
          curIndex = parseInt(key)
          break
        }
      }
      //后4步由对应审核员操作
      if(curIndex>=index){
        return false;
      }else{
        if(curIndex+1==index){
          let finishOp = checkUser(this.serviceOrder.worker) || checkUser(this.serviceOrder.reviewer)
          let reviewOp = checkUser(this.serviceOrder.worker) || checkUser(this.serviceOrder.cs)
          let workerOp = checkUser(this.serviceOrder.worker) || checkUser(this.serviceOrder.dataWorker)
          //设置状态
          if(index==8 && finishOp){
            //完成状态进行提醒
            Swal.fire({
              text: this.$t("serviceOrder.infoCompleted"),
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: this.$t("actions.ok"),
              cancelButtonText: this.$t("actions.cancel")
            }).then(result => {
              if (result.value) {
                if(checkUser(this.serviceOrder.worker)){
                  // 审核员操作Review，提示客服进行Review人员分配
                  reportReviewConfirm({serviceId:this.serviceOrder.serviceId,}).then((res)=>{
                    this.serviceOrder.logs = res.data.logs
                    Vue.swal(this.$t("serviceOrder.infoWaitingReportReviewerConfirm"));
                  })
                }else if(checkRole("SYS_ADMIN") ||checkUser(this.serviceOrder.reviewer)){
                  setServiceOrderStatus({serviceId:this.serviceOrder.serviceId,serviceStatus:this.stepMap[8]}).then((res)=>{
                    this.serviceOrder.logs = res.data.logs
                    this.serviceOrder.serviceStatus = res.data.serviceStatus
                  })
                }
              }
            });
          }else if(index==7 && checkUser(this.serviceOrder.reviewer)){
            this.handleReportReviewFinish();
          }else if(index==6 && reviewOp){
            if(checkRole("CS,SYS_ADMIN")){
              this.$refs.reviewerModal.show()
            }else if(checkUser(this.serviceOrder.worker)){
              Swal.fire({
              text: this.$t("serviceOrder.infoWorkFileUpload"),
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: this.$t("actions.ok"),
              cancelButtonText: this.$t("actions.cancel")
            }).then(result => {
              if (result.value) {
                if(checkRole("AUDIT")){
                  // 审核员操作Review，提示客服进行Review人员分配
                  reportReviewApplication({serviceId:this.serviceOrder.serviceId,}).then((res)=>{
                    this.serviceOrder.logs = res.data.logs
                    Vue.swal(this.$t("serviceOrder.infoReviewRequest"));
                  })
                }
              }
            });
            }
            return false;
          }else if(workerOp){
            setServiceOrderStatus({serviceId:this.serviceOrder.serviceId,serviceStatus:this.stepMap[index]}).then((res)=>{
              this.serviceOrder.logs = res.data.logs
              this.serviceOrder.serviceStatus = res.data.serviceStatus
              if(index==4&&this.serviceOrder.serviceType=="SLCP"){
                Vue.swal(this.$t("serviceOrder.confirmManday"));
              }else{
                Vue.swal(this.$t("serviceOrder.infoStatusSet"));
              }              
            })
          }
          return false;
        }
      }
    },
    handleChangeStatus(index){
      console.info(index)
    },
    handleFollowup(){
      this.$router.push({
          name: "follow-up",
          query: {
              id: this.serviceOrder.serviceId,
              type:'service',
          }
      })
    },
    handleSchedule(){
      this.schedule.serviceDate = this.serviceOrder.serviceDate
      this.schedule.serviceDateEnd = this.serviceOrder.serviceDateEnd
      this.schedule.schedulingDate = this.serviceOrder.schedulingDate
      this.schedule.schedulingDateEnd = this.serviceOrder.schedulingDateEnd
      this.schedule.workers = []
      this.schedule.dataWorkers = []
      this.schedule.travels = []
      this.schedule.dataTravels = []
      this.schedule.observers = []
      this.schedule.observerTravels = []
      if(this.serviceOrder.workers!=null){
        this.serviceOrder.workers.forEach((item)=>{
          let staffs = this.staffList.filter((staff)=>{
              return staff.userName == item
          })
          if(staffs.length==1){
            this.schedule.workers.push({userName:item,staffName:staffs[0].staffName})
          }
        })
      }
      if(this.serviceOrder.travels!=null){
        this.serviceOrder.travels.forEach((item)=>{
          let travels = this.travelList.filter((travel)=>{
              return travel.value == item
          })
          if(travels.length==1){
            this.schedule.travels.push({value:item,label:travels[0].label})
          }
        })
      }
      if(this.serviceOrder.dataWorkers!=null){
        this.serviceOrder.dataWorkers.forEach((item)=>{
          let staffs = this.staffList.filter((staff)=>{
              return staff.userName == item
          })
          if(staffs.length==1){
            this.schedule.dataWorkers.push({userName:item,staffName:staffs[0].staffName})
          }
        })
      }
      if(this.serviceOrder.dataTravels!=null){
        this.serviceOrder.dataTravels.forEach((item)=>{
          let travels = this.travelList.filter((travel)=>{
              return travel.value == item
          })
          if(travels.length==1){
            this.schedule.dataTravels.push({value:item,label:travels[0].label})
          }
        })
      }
      if(this.serviceOrder.observers!=null){
        this.serviceOrder.observers.forEach((item)=>{
          let staffs = this.staffList.filter((staff)=>{
              return staff.userName == item
          })
          if(staffs.length==1){
            this.schedule.observers.push({userName:item,staffName:staffs[0].staffName})
          }
        })
      }
      if(this.serviceOrder.observerTravels!=null){
        this.serviceOrder.observerTravels.forEach((item)=>{
          let travels = this.travelList.filter((travel)=>{
              return travel.value == item
          })
          if(travels.length==1){
            this.schedule.observerTravels.push({value:item,label:travels[0].label})
          }
        })
      }
      this.showScheduleForm = true;
    },
    handleSubmitSchedule(){
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      } else {
        this.showScheduleForm = false;
        let params = {...this.schedule}
        if(this.schedule.workers.length>0){
          params.worker = this.schedule.workers.map(item=>{return item.userName}).join(",")
        }
        if(this.schedule.travels.length>0){
          params.travelOption = this.schedule.travels.map(item=>{return item.value}).join(",")
        }
        if(this.schedule.workers.length>0){
          params.dataWorker = this.schedule.dataWorkers.map(item=>{return item.userName}).join(",")
        }
        if(this.schedule.travels.length>0){
          params.dataWorkerTravelOption = this.schedule.dataTravels.map(item=>{return item.value}).join(",")
        }
        if(this.schedule.observers.length>0){
          params.observer = this.schedule.observers.map(item=>{return item.userName}).join(",")
        }
        if(this.schedule.observerTravels.length>0){
          params.observerTravelOption = this.schedule.observerTravels.map(item=>{return item.value}).join(",")
        }
        params.serviceId = this.serviceOrder.serviceId
        // 删除reviewer
        delete params.reviewer
        serviceOrderSchedule(params).then(()=>{
          this.serviceOrder.serviceDate = params.serviceDate
          this.serviceOrder.serviceDateEnd = params.serviceDateEnd
          this.serviceOrder.schedulingDate = params.schedulingDate
          this.serviceOrder.schedulingDateEnd = params.schedulingDateEnd
          this.serviceOrder.worker = params.worker
          this.serviceOrder.workers = params.worker.split(",")
          this.serviceOrder.travels = params.travelOption.split(",")
          this.serviceOrder.dataWorker = params.dataWorker
          this.serviceOrder.dataWorkers = params.dataWorker.split(",")
          this.serviceOrder.dataTravels = params.dataWorkerTravelOption.split(",")
          this.serviceOrder.observer = params.observer
          this.serviceOrder.observers = params.observer.split(",")
          this.serviceOrder.observerTravels = params.observerTravelOption.split(",")
          console.info(this.serviceOrder)
          if(this.serviceOrder.serviceStatus=="PS"){
            this.serviceOrder.serviceStatus = "SC"
          }
          this.$forceUpdate()
        })
      }      
    },
    handleCancelSchedule(){
      this.showScheduleForm = false;
      this.submitted = false;
    },
    actionEnable(actionType){
      let role = checkRole("CS,SYS_ADMIN")
      if(actionType=="Generate" && this.serviceOrder.serviceStatus=="SC"){
        return true&&role
      }else if(actionType=="Send" && this.serviceOrder.serviceStatus=="SC" && this.serviceOrder.confirmationLetterFile!=null){
        return true&&role
      }else if(actionType=="Down-Conf-Letter" && this.serviceOrder.confirmationLetterFile!=null){
        return true&&role
      }else if(actionType=="Down-Conf-Letter-Rec" && this.serviceOrder.confirmationLetterReceipt!=null){
        return true
      }else if(actionType=="Review" && this.serviceOrder.serviceStatus=="PR" && this.serviceOrder.reviewer==this.userInfo.userName){
        return true
      }else if(actionType=="Send-Finish" && this.serviceOrder.serviceStatus=="F" && this.serviceOrder.cs==this.userInfo.userName ){
        return true
      }else if(actionType=="Send-PreAudit" && this.serviceOrder.serviceStatus=="SC" && this.serviceOrder.cs==this.userInfo.userName){
        return true&&role
      }else if(actionType=="Send-Survey" && this.serviceOrder.survey==null && this.serviceOrder.serviceStatus=="F" && this.serviceOrder.cs==this.userInfo.userName){
        return true
      }
      return false
    },
    handleDownload (file){
        const url=`/api-gateway/service/download?fileType=QT&fileName=${file}`
        downFile(url,file);
    },
    handleLog(){
      this.showLog = !this.showLog;
    },
    handleGenerateLetter(){
      if(this.serviceOrder.confirmationLetterFile!=null){
        Swal.fire({
          text: this.$t("serviceOrder.infoConfirmCreateLetter"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: this.$t("actions.ok"),
          cancelButtonText: this.$t("actions.cancel")
        }).then(result => {
          if (result.value) {
            this.generateConfirmLetterFile()
          }
        });
      }else{
        this.generateConfirmLetterFile()
      }
    },
    generateConfirmLetterFile(){
      generateConfirmLetter({serviceId:this.serviceOrder.serviceId}).then((res)=>{
        if(res.data==null){
          Vue.swal(this.$t("serviceOrder.errorGenerateConfirmationLetter"));
        }else{
          this.serviceOrder.confirmationLetterFile = res.data
          Vue.swal(this.$t("serviceOrder.infoConfirmationGenerated"));
        }
      })
    },
    handleSendLetter(emailType){
      if(emailType==1){
        //确认函
        this.confirmLetterEmail.to = this.serviceOrder.order.email
        this.confirmLetterEmail.attachment = [{name:"确认函",file:this.serviceOrder.confirmationLetterFile}]
        this.confirmLetterEmail.emailData = {}
        this.confirmLetterEmail.emailData.serviceId = this.serviceOrder.serviceId
        this.confirmLetterEmail.emailData.clientName = this.serviceOrder.order.clientName
        this.confirmLetterEmail.emailData.clientNameEn = this.serviceOrder.order.clientNameEn
        this.confirmLetterEmail.emailData.workDay = this.serviceOrder.workDay
        let orderDetail = JSON.parse(this.serviceOrder.order.orderDetail)
        this.confirmLetterEmail.emailData.serviceType = this.serviceOrder.order.orderType+" "+orderDetail.type+" "+orderDetail.model
        this.confirmLetterEmail.emailData.contact = this.serviceOrder.order.contact
        this.confirmLetterEmail.emailData.serviceDate = formatDate(this.serviceOrder.serviceDate,"MM月dd日")
        this.confirmLetterEmail.emailData.fileDate = formatDate(dateDiff(this.serviceOrder.serviceDate,-1),"MM月dd日")
        this.confirmLetterEmail.emailData.vbConfirmDate = formatDate(dateDiff(this.serviceOrder.serviceDate,-7),"MM月dd日")
        let attachmentType = "";
        if(this.serviceOrder.order.orderType=="SLCP"){
          this.templateId = "CONFIRM-LETTER-SLCP"
          attachmentType = "SLCP_VF_FILE"
        }else if(this.serviceOrder.order.orderType=="Higg"){
          this.templateId = "CONFIRM-LETTER-HIGG"
          attachmentType = "HIGG_VF_FILE" 
        }
        if(this.verificationFiles!=null && this.verificationFiles.length>0 && attachmentType!=""){
            let files = this.verificationFiles.filter(item=>{return item.fileCode==attachmentType || item.fileCode=="CCUPI" || item.fileCode=="CEL"})
            if(files.length>=1){
              for(let f=0;f<files.length;f++){
                this.confirmLetterEmail.attachment.push({name:files[f].fileName,file:files[f].fileOrgName})
              }
            }
        }  
      }else if(emailType==2){
        //报告发布通知
        this.confirmLetterEmail.to = this.serviceOrder.order.email
        this.confirmLetterEmail.emailData = {}
        this.confirmLetterEmail.emailData.serviceId = this.serviceOrder.serviceId
        this.confirmLetterEmail.emailData.clientName = this.serviceOrder.order.clientName
        this.confirmLetterEmail.emailData.clientNameEn = this.serviceOrder.order.clientNameEn
        this.confirmLetterEmail.emailData.contact = this.serviceOrder.order.contact
        if(this.serviceOrder.order.orderType=="SLCP"){
          this.templateId = "POST-NOTICE-SLCP"
        }else if(this.serviceOrder.order.orderType=="Higg"){
          this.templateId = "POST-NOTICE-HIGG"
        }
      }else if(emailType==3){
        //预审通知
        this.confirmLetterEmail.to = this.serviceOrder.order.email
        this.confirmLetterEmail.emailData = {}
        this.confirmLetterEmail.emailData.serviceId = this.serviceOrder.serviceId
        this.confirmLetterEmail.emailData.clientName = this.serviceOrder.order.clientName
        this.confirmLetterEmail.emailData.clientNameEn = this.serviceOrder.order.clientNameEn
        this.confirmLetterEmail.emailData.contact = this.serviceOrder.order.contact
        if(this.serviceOrder.order.orderType=="SLCP"){
          this.templateId = "QUANTITATIV-MV-HIGG"
        }else if(this.serviceOrder.order.orderType=="Higg"){
          this.templateId = "QUANTITATIV-MV-HIGG"
          if(this.verificationFiles!=null && this.verificationFiles.length>0){
            let files = this.verificationFiles.filter(item=>{return item.fileCode=="HIGG_VF_FILE" })
            if(files.length==1){
              this.confirmLetterEmail.attachment = [{name:files[0].fileName,file:files[0].fileOrgName}]
            }
          }  
        }
      }else if(emailType==4){
        //问卷调查
        this.confirmLetterEmail.to = this.serviceOrder.order.email
        this.confirmLetterEmail.emailData = {}
        this.confirmLetterEmail.emailData.serviceId = this.serviceOrder.serviceId
        this.confirmLetterEmail.emailData.clientName = this.serviceOrder.order.clientName
        this.confirmLetterEmail.emailData.clientNameEn = this.serviceOrder.order.clientNameEn
        this.confirmLetterEmail.emailData.contact = this.serviceOrder.order.contact
        this.templateId = "SERVICE-SURVEY"
      }     
      this.showSendMail=true
    },
    handleSendEmailResult(res){
      if(res.code==0){
        this.showSendMail=false
      }else if(res.code==1){
        Vue.swal(this.$t("serviceOrder.infoEmailSent"));
        this.showSendMail=false
      }else{
        Vue.swal(this.$t("serviceOrder.errorEmailSent"));
      }      
    },
    handleReviewer(){
      let reviewer = this.schedule.reviewer.userName
      setServiceOrderStatus({serviceId:this.serviceOrder.serviceId,serviceStatus:"PR",reviewer:reviewer}).then((res)=>{
        this.serviceOrder.logs = res.data.logs
        this.serviceOrder.serviceStatus = res.data.serviceStatus
        this.$refs.reviewerModal.hide()
        Vue.swal(this.$t("serviceOrder.infoReportReviewsuccess"));
      })
    },
    handleReportReviewFinish(){
      Swal.fire({
          text: this.$t("serviceOrder.infoConfirmReportReview"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: this.$t("actions.ok"),
          cancelButtonText: this.$t("actions.cancel")
        }).then(result => {
          if (result.value) {
            setServiceOrderStatus({serviceId:this.serviceOrder.serviceId,serviceStatus:this.stepMap[7]}).then((res)=>{
              this.serviceOrder.logs = res.data.logs
              this.serviceOrder.serviceStatus = res.data.serviceStatus
            })
            getTemplate("NOTICE-REVIEW-REPORT_FINISHED").then((res)=>{
              let audit = this.staffList.filter(item=>{return item.userName==this.serviceOrder.worker});
              let params = {
                  to:audit[0].email,
              }
              let emailData = {
                serviceId:this.serviceOrder.serviceId,
                serviceNo:this.serviceOrder.serviceNo,
                audit:this.serviceOrder.worker,
                clientName:this.serviceOrder.order.clientName
              }
              formatEmailInfo("zh-CN",params,emailData,res.data)
              sendEmail(params).then(()=>{
                Vue.swal(this.$t("serviceOrder.infoReportReviewCompletion"));
              }).catch(()=>{
                Vue.swal(this.$t("serviceOrder.errorSendReportReview"));
              })
            }).catch(()=>{
              Vue.swal(this.$t("serviceOrder.errorRetrievinTemplate"));
            });
          }
        });
    }
  }
};
</script>

<template>
  <div class="mb-0">
    <div class="row">
      <Steps
      :currentStep="currentStep"
      :totalSteps="8"
      :stepsLabel="stepsLabel"
      :totalWidth="900"
      :descMaxWidth="160"
      :validation="changeStatusEnable"
      @change="handleChangeStatus" />
    </div>
    <hr class="my-4">
    <div class="row">
      <div class="float-end d-md-block mb-3">
        <button type="button" class="btn btn-primary btn-sm mb-1" @click="handleFollowup"><i class="fab fa-rocketchat me-1 align-middle" ></i>{{$t("actions.followup")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" @click="handleAssignCS" v-if="showAssignButton">{{$t("actions.assignCS")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" v-if="actionEnable('Generate')" @click="handleGenerateLetter"><i class="fas fa-plus me-1 align-middle" ></i>{{$t("serviceOrder.gConfirmationLetter")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" v-if="actionEnable('Down-Conf-Letter')" @click="handleDownload(serviceOrder.confirmationLetterFile)"><i class="ion ion-md-cloud-download me-1 align-middle" ></i>{{$t("serviceOrder.confirmationLetter")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" v-if="actionEnable('Down-Conf-Letter-Rec')" @click="handleDownload(serviceOrder.confirmationLetterReceipt)"><i class="ion ion-md-cloud-download me-1 align-middle" ></i>{{$t("serviceOrder.reconfirmationLetter")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" v-if="actionEnable('Send')" @click="handleSendLetter(1)"><i class="ion ion-md-send me-1 align-middle" ></i> {{$t("serviceOrder.sConfirmationLetter")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" v-if="actionEnable('Send-Finish')" @click="handleSendLetter(2)"><i class="ion ion-md-send me-1 align-middle" ></i> {{$t("serviceOrder.sReportUpload")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" v-if="actionEnable('Send-PreAudit')" @click="handleSendLetter(3)"><i class="ion ion-md-send me-1 align-middle" ></i> {{$t("serviceOrder.sPreVerified")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" v-if="actionEnable('Send-Survey')" @click="handleSendLetter(4)"><i class="ion ion-md-send me-1 align-middle" ></i> {{$t("serviceOrder.sSurvey")}}</button>
        <button type="button" class="btn btn-primary btn-sm mb-1 ms-1" @click="handleLog"><i class="fas fa-calendar-alt me-1 align-middle" ></i>{{$t("serviceOrder.operationLog")}}</button>
      </div>
    </div>
    <div class="row" v-if="showSendMail">
        <email :templateId="templateId" :data="confirmLetterEmail" @send="handleSendEmailResult"></email>
    </div>
    <div class="row" v-if="!showSendMail">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                  <div class="col-md-12 mb-3">
                    <div class="infoField d-flex align-items-center">
                      <div class="flex-grow-1">{{$t("serviceOrder.schedulingInfo")}}({{ serviceOrder.workDay }} {{ $t("serviceOrder.personday")}}):</div>
                      <button type="button" class="btn btn-success btn-sm" @click="handleSchedule" v-if="showScheduleButton">{{$t("serviceOrder.btSchedule")}}</button>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="!showScheduleForm">
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.verificationStartDate")}}</div>
                      <div class="infoData">{{serviceOrder.serviceDate}}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.verificationEndDate")}}</div>
                      <div class="infoData">{{serviceOrder.serviceDateEnd}}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.announcedType")}}</div>
                      <div class="infoData">{{schedulingModeText}}</div>
                  </div>
                  <div class="col-md-12" v-if="serviceOrder.schedulingMode=='3'">
                      <div class="infoField">{{$t("serviceOrder.announcedStartDate")}}</div>
                      <div class="infoData">{{serviceOrder.schedulingDate}}</div>
                  </div>
                  <div class="col-md-12" v-if="serviceOrder.schedulingMode=='3'">
                      <div class="infoField">{{$t("serviceOrder.announcedEndDate")}}</div>
                      <div class="infoData">{{serviceOrder.schedulingDateEnd}}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.leadVerifier")}}</div>
                      <div class="infoData">
                        <span class="badge me-1 badge-soft-info" v-for="item in serviceOrder.workers" :key="item">{{getAuditUserText(item)}}</span>
                      </div>
                  </div>  
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.travelSchedule")}}</div>
                      <div class="infoData">
                        <span class="badge me-1 badge-soft-info" v-for="item in serviceOrder.travels" :key="item">{{getTravelText(item)}}</span>
                      </div>
                  </div> 
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.quantitativeMetricsVerifier")}}</div>
                      <div class="infoData">
                        <span class="badge me-1 badge-soft-info" v-for="item in serviceOrder.dataWorkers" :key="item">{{getAuditUserText(item)}}</span>
                      </div>
                  </div>  
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.travelScheduleQM")}}</div>
                      <div class="infoData">
                        <span class="badge me-1 badge-soft-info" v-for="item in serviceOrder.dataTravels" :key="item">{{getTravelText(item)}}</span>
                      </div>
                  </div> 
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.observer")}}</div>
                      <div class="infoData">
                        <span class="badge me-1 badge-soft-info" v-for="item in serviceOrder.observers" :key="item">{{getAuditUserText(item)}}</span>
                      </div>
                  </div>  
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.travelScheduleObserver")}}</div>
                      <div class="infoData">
                        <span class="badge me-1 badge-soft-info" v-for="item in serviceOrder.observerTravels" :key="item">{{getTravelText(item)}}</span>
                      </div>
                  </div>                   
                  <div class="col-md-12">
                      <div class="infoField">{{$t("serviceOrder.scheduleConfirmationDate")}}</div>
                      <div class="infoData">{{serviceOrder.confirmationLetterReceiptDate}}</div>
                  </div>      
              </div>
              <div class="row" v-if="showScheduleForm">
                <div class="col-md-12 mb-3">
                    <label class="form-label">{{$t("serviceOrder.verificationStartDate")}}</label>
                    <date-picker
                      v-model="schedule.serviceDate"
                      format="YYYY-MM-DD"
                      value-type="format"
                      :disabled-date="disabledDates"
                      :first-day-of-week="1"
                      lang="en"
                      :class="{
                          'is-invalid': submitted && $v.schedule.serviceDate.$error,
                      }"
                    ></date-picker>
                    <div
                        v-if="submitted && $v.schedule.serviceDate.$error"
                        class="invalid-feedback"
                        >
                            <span v-if="!$v.schedule.serviceDate.required">
                              开始日期不能为空.
                            </span>
                    </div>
                </div>
                <div class="col-md-12 mb-3">
                    <label class="form-label">{{$t("serviceOrder.verificationEndDate")}}</label>
                    <date-picker
                      v-model="schedule.serviceDateEnd"
                      format="YYYY-MM-DD"
                      value-type="format"
                      :disabled-date="disabledDates"
                      :first-day-of-week="1"
                      lang="en"
                      :class="{
                          'is-invalid': submitted && $v.schedule.serviceDateEnd.$error,
                      }"
                    ></date-picker>
                    <div
                        v-if="submitted && $v.schedule.serviceDateEnd.$error"
                        class="invalid-feedback"
                        >
                            <span v-if="!$v.schedule.serviceDateEnd.required">
                              结束日期不能为空.
                            </span>
                    </div>
                </div>
                <div class="col-md-12 mb-3">
                  <label class="form-label">{{$t("serviceOrder.leadVerifier")}}</label>
                  <multiselect v-model="schedule.workers" :options="staffList" :multiple="true"
                      label="staffName"
                      track-by="userName"
                  ></multiselect>
                </div>
                <div class="col-md-12 mb-3">
                  <label class="form-label">{{$t("serviceOrder.travelSchedule")}}</label>
                  <multiselect v-model="schedule.travels" :options="travelList" :multiple="true"
                      label="label"
                      track-by="value"
                  ></multiselect>
                </div>
                <div class="col-md-12 mb-3">
                  <label class="form-label">{{$t("serviceOrder.quantitativeMetricsVerifier")}}</label>
                  <multiselect v-model="schedule.dataWorkers" :options="staffList" :multiple="true"
                      label="staffName"
                      track-by="userName"
                  ></multiselect>
                </div>
                <div class="col-md-12 mb-3">
                  <label class="form-label">{{$t("serviceOrder.travelScheduleQM")}}</label>
                  <multiselect v-model="schedule.dataTravels" :options="travelList" :multiple="true"
                      label="label"
                      track-by="value"
                  ></multiselect>
                </div>
                <div class="col-md-12 mb-3">
                  <label class="form-label">{{$t("serviceOrder.observer")}}</label>
                  <multiselect v-model="schedule.observers" :options="staffList" :multiple="true"
                      label="staffName"
                      track-by="userName"
                  ></multiselect>
                </div>
                <div class="col-md-12 mb-3">
                  <label class="form-label">{{$t("serviceOrder.travelScheduleObserver")}}</label>
                  <multiselect v-model="schedule.observerTravels" :options="travelList" :multiple="true"
                      label="label"
                      track-by="value"
                  ></multiselect>
                </div>
                <div class="col-md-12 mb-3" v-if="serviceOrder.schedulingMode=='3'">
                    <label class="form-label">{{$t("serviceOrder.announcedStartDate")}}</label>
                    <date-picker
                      v-model="schedule.schedulingDate"
                      format="YYYY-MM-DD"
                      value-type="format"
                      :disabled-date="disabledDates"
                      :first-day-of-week="1"
                      lang="en"
                      :class="{
                          'is-invalid': submitted && $v.schedule.schedulingDate.$error,
                      }"
                    ></date-picker>
                    <div
                        v-if="submitted && $v.schedule.schedulingDate.$error"
                        class="invalid-feedback"
                        >
                            <span v-if="!$v.schedule.schedulingDate.req">
                              开始日期不能为空.
                            </span>
                    </div>
                </div>
                <div class="col-md-12 mb-3" v-if="serviceOrder.schedulingMode=='3'">
                    <label class="form-label">{{$t("serviceOrder.announcedEndDate")}}</label>
                    <date-picker
                      v-model="schedule.schedulingDateEnd"
                      format="YYYY-MM-DD"
                      value-type="format"
                      :disabled-date="disabledDates"
                      :first-day-of-week="1"
                      lang="en"
                      :class="{
                          'is-invalid': submitted && $v.schedule.schedulingDateEnd.$error,
                      }"
                    ></date-picker>
                    <div
                        v-if="submitted && $v.schedule.schedulingDateEnd.$error"
                        class="invalid-feedback"
                        >
                            <span v-if="!$v.schedule.serviceDateEnd.req">
                              结束日期不能为空.
                            </span>
                    </div>
                </div>
                <div class="col-md-12 ">
                  <button type="button" class="btn btn-info btn-sm" @click="handleSubmitSchedule">{{$t("common.submit")}}</button>
                  <button type="button" class="btn btn-light btn-sm ms-1" @click="handleCancelSchedule">{{$t("common.cancel")}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="showLog">
          <div class="card">
            <div class="card-body">
              <div class="row">
                  <div class="col-md-12 mb-3">
                      <div class="infoField">服务日志:</div>
                  </div>
                  <div class="col-md-12 mb-3">
                    <div class="log-messages">
                      <div class="message-item"  v-for="(item) in serviceOrder.logs" :key="item.id">
                          <span class="icon"></span>
                            <h5>{{item.logDate}}</h5>
                            <p>{{item.description}}</p>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                  <div class="col-md-12 mb-3">
                      <div class="infoField">{{$t("serviceOrder.contactInfo")}}:</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("common.clientNameLocal")}}</div>
                      <div class="infoData">{{ order.clientName }}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("common.clientNameEn")}}</div>
                      <div class="infoData">{{ order.clientNameEn }}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("common.addressLocal")}}</div>
                      <div class="infoData">{{ order.address }}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("common.addressEn")}}</div>
                      <div class="infoData">{{ order.addressEn }}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("common.contact")}}</div>
                      <div class="infoData">{{ order.contact }}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("common.title")}}</div>
                      <div class="infoData">{{ order.title }}</div>
                  </div>
                  <div class="col-md-12">
                      <div class="infoField">{{$t("common.mobile")}}</div>
                      <div class="infoData">{{ order.mobile }}</div>
                  </div>     
                  <div class="col-md-12">
                      <div class="infoField">{{$t("common.email")}}</div>
                      <div class="infoData">{{ order.email }}</div>
                  </div>                
              </div>   
            </div>
          </div>  
        </div>
        <b-modal
          id="reviewerModal"
          ref="reviewerModal"
          centered
          title="报告审核员选择"
          title-class="font-18"
          hide-footer
          >
            <div>
              <multiselect v-model="schedule.reviewer" :options="reviewerList" :multiple="false"
                      label="staffName"
                      track-by="userName"
              ></multiselect>
            </div>
            <div>
              <button type="button" class="btn btn-primary btn-sm" @click="handleReviewer">{{$t("common.ok")}}</button>
            </div>
        </b-modal>
        <b-modal
        id="csModal"
        ref="csModal"
        centered
        :title='$t("actions.assignCS")'
        title-class="font-18"
        hide-footer
        >
          <div>
            <multiselect v-model="curCS" :options="csList" :multiple="false"
                    label="staffName"
                    track-by="userName"
            ></multiselect>
          </div>
          <div>
            <button type="button" class="btn btn-primary btn-sm" @click="assignCS">{{$t("common.ok")}}</button>
          </div>
      </b-modal>
    </div>           
  </div>
</template>
<style lang="scss" scoped>
.infoField{
  font-weight: bold;
}
.infoData{
  min-height: 30px;
}
.is-invalid .mx-input-wrapper .mx-input{
    border: 1px solid #ec4561 !important;
  }
  .is-invalid .mx-input-wrapper .mx-icon-calendar{
    color: #ec4561 !important;
  }
  .log-messages{
      border-left: #bfcbd9 solid 2px;
      padding-left: 20px;
      margin-left: 20px;
        .message-item {
          position: relative;
          span.icon{
            border-radius: 100%;
            border: #bfcbd9 solid 1px;
            background-color: #bfcbd9;
            width: 15px;
            height: 15px;
            font-size: 10px;
            display: inline-block;
            text-align: center;
            position: absolute;
            left: -29px;
            top:4px;
          }
        }
    }
</style>